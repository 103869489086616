<template>
  <v-card
    width="100%"
    max-width="300"
    outlined
    v-bind="{ ...$attrs }"
    v-on="{ ...$listeners, ...$on }"
  >
    <v-row class="pa-4">
      <v-col cols="auto" class="align-center d-flex">
        <v-avatar rounded="100" color="primary" size="50">
          <v-icon dark v-text="icon" />
        </v-avatar>
      </v-col>
      <v-col>
        <span v-if="diff.value" :style="`color: ${diff.color}`" class="float-right">{{ diff.text }}</span>
        <h2 class="text-no-wrap" v-if="value !== null" v-text="value + unit" />
        <span v-text="texto" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: String,
    unit: String,
    value: String | Number,
    texto: String,
    increment: Number | String,
    incrementWay: String,
  },
  computed: {
    diff() {
      if (this.increment == 0 || this.increment == null || this.increment == undefined) return { value: 0 };
      let sign = Number(this.increment) > 0 ? '+' : '-';
      let colors = ['var(--v-error-darken1)', 'var(--v-error-darken2)', 'var(--v-error-base)', 'var(--v-success-darken1)', 'var(--v-success-darken2)', 'var(--v-success-base)'];
      if (this.incrementWay == 'up') colors.reverse()
      let good = colors.slice(0, Math.ceil(colors.length / 2))
      let bad = colors.slice(-Math.ceil(colors.length / 2))

      return {
        value: this.increment, color: this.increment > 0 ?
          good[Math.floor(good.length * (this.increment / 100))] :
          bad[Math.floor(bad.length * (this.increment / 100))]
        , text: [sign, ' ', Math.abs(this.increment), '%'].join('')
      }
    }
  }
}
</script>